import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import FullContainer from "../../common/FullContainer";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" style={{margin: '0 10px'}}/>
      </a>
    );
  };

  return (
    <>
      <Extra>
        <FullContainer header={false}>
          <Row
            justify="center"
            align="middle"
            style={{ paddingTop: "2rem" }}
          >
            <Col span={24}>
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                    src="logo.svg"
                    aria-label="homepage"
                    width="101px"
                    height="64px"
                  />
                </LogoContainer>
              </NavLink>
            </Col>
            <Col span={24}>
              <FooterContainer>
                <SocialLink
                  href="https://www.linkedin.com/company/doitnifty/"
                  src="linkedin2.svg"
                />
                {/*<SocialLink
                  href="https://google.com"
                  src="facebook.svg"
                />
                <SocialLink
                  href="https://google.com"
                  src="instagram.svg"
                />
                <SocialLink
                  href="https://google.com"
                  src="tiktok.svg"
                />*/}
                <a
                  href="https://ko-fi.com/Y8Y7H8BNJ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                </a>
              </FooterContainer>
            </Col>
          </Row>
        </FullContainer>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
