const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    id: "home",
  },
  {
    path: ["/contact"],
    exact: true,
    component: "Contact",
    id: "contact",
  },
];

export default routes;
