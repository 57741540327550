import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Inter Light";
        src: url("/fonts/Inter-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Inter Bold";
        src: url("/fonts/Inter-Bold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Inter SemiBold";
        src: url("/fonts/Inter-SemiBold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Inter Light', sans-serif;
        height: 100%;
        margin: 0;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: rgb(0,0,255);
        background: linear-gradient(90deg, rgba(0,0,255,0.08) 0%, rgba(11,208,208,0.3) 50%, rgba(0,0,255,0.05) 100%);
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Inter SemiBold', serif;
        color: #18216d;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #18216d;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .szh-accordion {
        max-width: 600px;
        padding: 1.5rem;
        text-align: left;
        background: #1B1E26;
        color: #fff;
        height: auto;
        margin: 1rem 0.5rem;
        background: linear-gradient(#1B1E26, #1B1E26) padding-box,
        linear-gradient(to right, #0000FF, #00FFFF) border-box;
        border-radius: 25px;
        border: 1px solid transparent;
    }
    .szh-accordion__item-heading {
        font-size: 0;
    }
    .szh-accordion__item-btn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        color: #fff;
        font-size: 1rem;
    }
    .szh-accordion__item-content {
        margin-top: 1em;
        font-size: 0.9rem;
        text-align: left;
        color: #AFBACA;
    }
    .ant-drawer-content-wrapper {
        width: 60%;
    }
`;
