import styled from "styled-components";

export const StyledContainer = styled("div")<{
  border?: boolean;
  header?: boolean;
}>`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: ${(p) => (p.header ? '0rem' : '5rem')};
  border-top: ${(p) => (p.border ? "1px solid #CDD1D4" : "")};
`;